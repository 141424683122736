import router from '@/router';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { dispatchAxiosErrorAlert, extractFieldsData, injectStrict } from '@/lib/misc';
import { StoreKey } from '@/symbols';
export default defineComponent({
    setup() {
        const store = injectStrict(StoreKey);
        const validatorRef = ref(null);
        const state = reactive({
            visible: store.state.auth.user === null,
            formData: computed(() => extractFieldsData(fields))
        });
        const fields = reactive({
            email: {
                label: 'Adresa e-mail',
                value: '',
                rules: {
                    required: true,
                    email: true
                }
            },
            password: {
                label: 'Parola',
                value: '',
                rules: {
                    required: true
                }
            }
        });
        const onSubmit = () => validatorRef.value && validatorRef.value.validate()
            .then(() => store.dispatch('auth/login', state.formData)
            .then(() => router.push({ name: 'home' }))
            .catch((err) => {
            if (validatorRef.value) {
                if (err.response?.status === 422) {
                    validatorRef.value.setErrors(err.response?.data?.data || []);
                }
                else {
                    dispatchAxiosErrorAlert(store, err);
                }
            }
        }));
        watch(() => state.visible, v => {
            if (!v) {
                setTimeout(() => { router.push({ name: 'home' }); }, 300);
            }
        }, { immediate: true });
        return {
            state,
            fields,
            validatorRef,
            onSubmit
        };
    }
});
